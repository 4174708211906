import React from "react";

export interface AccordionProps {
    title: string | React.ReactNode;
    tagline?: string;
    defaultOpen?: boolean;
    showLargeHeader?: boolean;
    showMediumHeader?: boolean;
    showSmallHeader?: boolean;
    showToggleIcons?: boolean;
    showToggleLabel?: boolean;
    showLargeArrow?: boolean;

    className?: string;
    children: React.ReactNode;

    animation?: boolean;
    headerFullWidth?: boolean;
    noPaddingHeader?: boolean;
    allowThickBorder?: boolean;
    toggleOnWrapper?: boolean;
    onToggle?: () => void;
    isOpen?: boolean;
}

export enum AccordionViewState {
    Collapsed = "COLLAPSED",
    Expanded = "EXPANDED",
}
