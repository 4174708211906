import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    DA_CartData,
    DA_CartDataApi,
    DA_CartLineApi,
} from "@danishagro/shared/src/interfaces/cartData.interface";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { FetchOptions } from "@danishagro/shared/src/interfaces/fetchOptions.interface";
import { DA_CartPriceData } from "@danishagro/shared/src/interfaces/price.interface";
import { usePrevious } from "@danishagro/shared/src/hooks/usePrevious.hook";
import { useCustomer } from "@danishagro/shared/src/hooks/useCustomer.hook";
import { DA_Toast } from "@danishagro/shared/src/components/molecules/Toast/Toast.component";
import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";
import { useTranslations } from "../translations/translations.context";
import { getRelativeRootOnLocalhost } from "../../helpers/getRelativeUrlOnLocalhost.helper";
import { useRequest } from "../../hooks/useRequest.hook";
import { ExpressDeliveryModes } from "../../interfaces/expressDeliveryModes.interface";
import { DA_B2bImageProps } from "../../interfaces/b2bImageProps.interface";
import { getB2bImage } from "../../helpers/getB2bImage.helper";
import { useAppData } from "../appData.context";
import { useCartRequest } from "./hooks/useCartRequest.hook";
import { useCartPrice } from "./hooks/useCartPrice.hook";

export const CROP_PROTECTION_DELIVERY_MODE_DEFAULT = "11";

interface CartArgs {
    quantity: number;
    productId: string;
}

interface ContactArgs {
    companyName: string;
    cvr: string;
    email: string;
    phone: string;
}

interface DeliveryArgs {
    billingAddress?: {
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    };
    shippingAddress: {
        id: string;
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    };
    shippingSameAsBilling: boolean;
}

interface DeliveryOptionsArgs {
    deliveryModeCode: string;
    deliveryDate: string;
    reference: string;
    phoneNumber: string;
}

interface DeliveryOptionsArgs2 extends DeliveryOptionsArgs {
    id: string;
    secret?: string;
}

interface OrderlineDriverMessageArgs {
    lineId: string;
    driverMessage: string;
}

interface CompleteOrder extends DA_CartData {
    price: DA_CartPriceData;
    completedDate: Date;
}
interface CartHook {
    cartId?: string;
    secret?: string;
    orderSecret?: string;
    priceData: DA_CartPriceData | undefined;
    priceStatus: ReturnType<typeof useCartPrice>["priceStatus"];
    customerDetails: DA_CartData["customerDetails"];
    shippingDetails: DA_CartData["shippingDetails"];
    cropProtection: DA_CartData["showCropProtectionDeliveryMode"];
    latestShippingDate?: Date;
    orderLines: DA_CartOrderLine[];
    updateCartOrderLines: (cartData: DA_CartData["orderLines"]) => void;
    updateOrderlineDriverMessage: (
        properties: OrderlineDriverMessageArgs,
        options?: FetchOptions
    ) => Promise<boolean>;

    addToCart: (properties: CartArgs, options?: FetchOptions) => Promise<DA_CartData>;
    updateCart: (properties: CartArgs, options?: FetchOptions) => Promise<DA_CartData>;
    removeFromCart: (properties: CartArgs, options?: FetchOptions) => Promise<DA_CartData>;
    resetCart: () => void;
    saveContactInfo: (properties: ContactArgs, options?: FetchOptions) => Promise<DA_CartData>;
    updateExpressDeliveryModes: (
        properties: ExpressDeliveryModes,
        options?: FetchOptions
    ) => Promise<DA_CartData>;
    expressDeliveryModes: ExpressDeliveryModes;
    saveDeliveryInfo: (properties: DeliveryArgs, options?: FetchOptions) => Promise<DA_CartData>;
    completeOrderSimple: (
        properties: DeliveryOptionsArgs,
        options?: FetchOptions
    ) => Promise<string>;
    completeOrderSimple2: (
        properties: DeliveryOptionsArgs2,
        options?: FetchOptions
    ) => Promise<string>;
    completeOrder: (options?: FetchOptions) => Promise<string>;
    getCompletedOrder: (cartId: string, options?: FetchOptions) => Promise<CompleteOrder>;

    isLoadingCart: boolean;
    isUpdatingCart: boolean;
    isFarmInTimeCart: boolean;
}

const CartContext = React.createContext<CartHook>({} as CartHook);

type Props = {
    customerNumber?: string;
    orderSecret?: string;
    children: React.ReactNode;
};

export const CartProvider = ({
    customerNumber,
    orderSecret: givenOrderSecret,
    children,
}: Props): JSX.Element => {
    const [cartId, setCartId] = useState<string>();
    const [orderSecret, _setOrderSecret] = useState<string>(givenOrderSecret);
    const [isLoadingCart, setIsLoadingCart] = useState(false);
    const [isUpdatingCart, setIsUpdatingCart] = useState(false);
    const [customerDetails, setCustomerDetails] = useState<DA_CartData["customerDetails"]>();
    const [shippingDetails, setShippingDetails] = useState<DA_CartData["shippingDetails"]>();
    const [cropProtection, setCropProtection] =
        useState<DA_CartData["showCropProtectionDeliveryMode"]>();
    const [expressDeliveryModes, setExpressDeliveryModes] = useState<ExpressDeliveryModes>({
        isExpressDelivery: false,
        cropProtectionDeliveryMode: CROP_PROTECTION_DELIVERY_MODE_DEFAULT,
    });
    const [orderLines, setOrderLines] = useState<DA_CartOrderLine[]>([]);
    const { getDictionaryString } = useTranslations();
    const { customer } = useCustomer();
    const previousCustomerNumber = usePrevious(customerNumber);
    const { currentCulture } = useAppData();

    const request = useRequest();
    const b2bRequest = useCartRequest();
    const { priceData, priceStatus, latestShippingDate } = useCartPrice(
        customerNumber,
        cartId,
        orderLines,
        expressDeliveryModes,
        isUpdatingCart,
        shippingDetails
    );

    const getStoredCartId = useCallback(() => {
        const localCartId = localStorage.getItem(`cartId:${cartId}`);
        if (cartId) {
            setCartId(cartId);
            return localCartId;
        }
        return null;
    }, [cartId]);

    const storeCartId = useCallback(
        (id: string) => {
            if (cartId !== id) {
                localStorage.setItem(`cartId:${customerNumber}`, id);
                setCartId(id);
            }
        },
        [cartId, customerNumber]
    );

    const updateCartOrderLines = useCallback((orderLines: DA_CartData["orderLines"]) => {
        setOrderLines(orderLines);
    }, []);

    const resetCartId = useCallback(() => {
        localStorage.removeItem(`cartId:${customerNumber}`);
        setCartId(undefined);
    }, [customerNumber]);

    // ---------------------------------------------------------------

    // TODO: Move mappers to cart helper

    const mapToCartOrderLine = useCallback((line: DA_CartLineApi): DA_CartOrderLine => {
        const { id, productId, productNumber, productVariantId, quantity, url } = line;

        const img: DA_B2bImageProps = {
            url: line.imageUrl,
            width: 85,
            height: 85,
        };
        const sizedImage = getB2bImage(img, {
            maxWidth: 85,
            maxHeight: 85,
        });

        return {
            id,
            productId,
            productName: line.name,
            productNumber,
            productVariantId,
            quantity,
            url,

            driverMessage: line.data?.driverMessage,
            allowCropProtectionDelivery: line.data?.allowCropProtectionDelivery,
            allowExpressDelivery: line.data?.allowExpressDelivery,
            unit: line.data?.unit,

            // TODO: get these values from API later and map them correctly
            image: sizedImage,
            imageUrl: line.imageUrl,
            foVariantId: null,
            productVariantName: "",
        };
    }, []);

    const mapToCartData = useCallback(
        (data: DA_CartDataApi): DA_CartData => {
            const addressData = {
                ...data.shippingDetails?.shippingAddress,
                id: data.shippingDetails?.shippingAddress.id,
                countryCode: data.shippingDetails?.shippingAddress.country,
                companyName: data.shippingDetails?.shippingAddress.name,
            };

            return {
                id: data.id,
                orderLines: data.lines.map((line) => mapToCartOrderLine(line)),
                state: data.basketStatus,
                secret: data.secret,
                customerNumber: data.customerNumber,
                showCropProtectionDeliveryMode: data.data?.showCropProtectionDeliveryMode,

                customerDetails: {
                    ...addressData,
                    email: undefined,
                    phone: undefined,
                    cvr: undefined,
                },
                shippingDetails: {
                    ...addressData,
                    locationId: data.shippingDetails?.shippingAddress.locationId,
                },

                // TODO: get these values from API later and map them correctly
                farmInTimeReferenceId: null,
                farmInTimeDriverMessage: null,
                isCompleted: false,
                isExpressDelivery: false,
                transactionId: null,
            };
        },
        [mapToCartOrderLine]
    );

    const mapToOrder = useCallback(
        (data: DA_CartDataApi): CompleteOrder => {
            const cartData = mapToCartData(data);
            const result = {
                ...cartData,

                // TODO: figure out missing fields
                price: data.data?.price,
                completedDate: data.updatedDate,
            };
            return result;
        },
        [mapToCartData]
    );

    const mapToCartLineApi = useCallback((args: CartArgs): DA_CartLineApi => {
        return {
            id: null,
            productId: args.productId,
            quantity: args.quantity,
            name: null,
            description: null,
            imageUrl: null,
            data: null,
        };
    }, []);

    // ---------------------------------------------------------------

    const updateCartData = useCallback(
        (cartData: Omit<DA_CartData, "price">) => {
            if (cartData) {
                setCartId(cartData.id);
                setCustomerDetails(cartData.customerDetails);
                setCropProtection(cartData.showCropProtectionDeliveryMode);
                setOrderLines(
                    // TODO: Remove this map-function when FO can handle express delivery
                    cartData.orderLines.map((line) => ({ ...line, allowExpressDelivery: false }))
                );
                setShippingDetails(cartData.shippingDetails);
                setExpressDeliveryModes({
                    isExpressDelivery: cartData.isExpressDelivery,
                    cropProtectionDeliveryMode:
                        cartData.showCropProtectionDeliveryMode?.items[0].id,
                });
                storeCartId(cartData.id);
            }
        },
        [storeCartId]
    );

    const resetCart = useCallback(() => {
        setCartId(undefined);
        setCustomerDetails(undefined);
        setOrderLines([]);
        setShippingDetails(undefined);
        resetCartId();
    }, [resetCartId]);

    const getCart = useCallback(
        (options?: FetchOptions) => {
            setIsLoadingCart(true);

            const properties = {};
            // Farm in time cart context
            if (customerNumber && givenOrderSecret) {
                properties["customerNumber"] = customerNumber;
                properties["secret"] = orderSecret;
                properties["mergeCart"] = "false";
            }
            // Logged in get latest cart
            else if (customerNumber) {
                // properties["customerNumber"] = customerNumber;

                //TODO:  use current culture. Currently api fails on currentCulture
                properties["culture"] = "da";
            }
            // Receipt
            else {
                properties["secret"] = orderSecret;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .get(`/carts/customers/${customerNumber}`, properties, options)
                    .then((data) => {
                        if (data) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsLoadingCart(false))
            );
        },
        [customerNumber, givenOrderSecret, orderSecret, b2bRequest, updateCartData, mapToCartData]
    );

    const getCartById = useCallback(
        (options?: FetchOptions) => {
            setIsLoadingCart(true);

            const properties = {};
            // Farm in time cart context
            if (customerNumber) {
                properties["customerNumber"] = customerNumber;
                properties["culture"] = "da";
                properties["mergeCart"] = "true";
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .get(`/carts/${orderSecret}`, properties, options)
                    .then((data) => {
                        if (data) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsLoadingCart(false))
            );
        },
        [customerNumber, orderSecret, b2bRequest, updateCartData, mapToCartData]
    );

    const addToCart: CartHook["addToCart"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);
            const props = mapToCartLineApi(properties);

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post(`/carts/customers/${customerNumber}/lines`, props, options)
                    .then((data) => {
                        if (data && data?.id) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                            toast.custom(() => (
                                <DA_Toast
                                    href={`${getRelativeRootOnLocalhost(B2B_SITE_ORIGIN)}/cart`}
                                    goTo={getDictionaryString("ToastShowCart")}
                                    message={
                                        <>
                                            <div>{getDictionaryString("ToastAddedToCartText")}</div>
                                            {customer && (
                                                <div className="break-all">
                                                    {getDictionaryString("ToastAccountText")}
                                                    <strong>{customer?.name}</strong>
                                                </div>
                                            )}
                                        </>
                                    }
                                />
                            ));
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [
            customerNumber,
            b2bRequest,
            mapToCartData,
            mapToCartLineApi,
            updateCartData,
            getDictionaryString,
            customer,
        ]
    );

    const updateCart: CartHook["updateCart"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {};

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            if (cartId) {
                props["basketId"] = cartId;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .patch(
                        `/carts/customers/${customerNumber}/lines/${
                            properties["id"]
                        }/updatequantity?${new URLSearchParams(props).toString()}`,
                        properties.quantity,
                        options
                    )
                    .then((data) => {
                        if (data) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, mapToCartData, updateCartData, cartId]
    );

    const removeFromCart: CartHook["removeFromCart"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {};

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            const lineId = properties["id"];

            return new Promise((resolve, reject) =>
                b2bRequest
                    .delete(`/carts/customers/${customerNumber}/lines/${lineId}`, props, options)
                    .then((data) => {
                        if (data && data?.id) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, updateCartData, mapToCartData]
    );

    const saveContactInfo: CartHook["saveContactInfo"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                ...properties,
                orderSecret,
            };

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post("/checkout/SaveContactInfo", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, orderSecret, updateCartData]
    );

    const updateExpressDeliveryModes: CartHook["updateExpressDeliveryModes"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                id: cartId,
                cropProtectionDeliveryMode: properties.cropProtectionDeliveryMode,
            };

            return new Promise((resolve, reject) =>
                b2bRequest
                    .patch(
                        `/carts/customers/${customerNumber}/setcropprotectiondeliverymode`,
                        props,
                        options
                    )
                    .then((data) => {
                        if (data.id) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, cartId, customerNumber, mapToCartData, updateCartData]
    );

    const saveDeliveryInfo: CartHook["saveDeliveryInfo"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                id: cartId,
                locationId: properties.shippingAddress?.id || "",
            };

            return new Promise((resolve, reject) =>
                b2bRequest
                    .patch(`/carts/customers/${customerNumber}/savedeliveryinfo`, props, options)
                    .then((data) => {
                        if (data.id) {
                            updateCartData(mapToCartData(data));
                            resolve(data);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, cartId, customerNumber, mapToCartData, updateCartData]
    );

    const completeOrder: CartHook["completeOrder"] = useCallback(
        (options) => {
            const props = {
                id: cartId,
            };

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post(`/orders/${customerNumber}/complete?version=2`, props, options)
                    .then((data) => {
                        if (data.id) {
                            resolve(data.id);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => {
                        console.error("ERROR", error);
                        reject(error);
                    })
            );
        },
        [cartId, b2bRequest, customerNumber]
    );

    const completeOrderSimple: CartHook["completeOrderSimple"] = useCallback(
        (properties, options) => {
            const props = {
                orderSecret,
            };

            const params = new URLSearchParams();
            params.append("deliveryDate", properties.deliveryDate);
            params.append("deliveryModeCode", properties.deliveryModeCode);
            params.append("customerRequisitionNumber", properties.reference);
            params.append("phoneNumber", properties.phoneNumber);

            return new Promise((resolve, reject) =>
                request
                    .post(
                        `/orders/${customerNumber}/completesimple?${params.toString()}`,
                        props,
                        options
                    )
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            resolve(orderSecret);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
            );
        },
        [request, customerNumber, orderSecret]
    );

    const completeOrderSimple2: CartHook["completeOrderSimple2"] = useCallback(
        (properties, options) => {
            const props = {
                id: properties.id,
                secret: properties.secret,
            };

            const params = new URLSearchParams();
            params.append("deliveryDate", properties.deliveryDate);
            params.append("deliveryModeCode", properties.deliveryModeCode);
            params.append("customerRequisitionNumber", properties.reference);
            params.append("phoneNumber", properties.phoneNumber);
            params.append("culture", currentCulture);

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post(
                        `/orders/${customerNumber}/completesimple?version=2&${params.toString()}`,
                        props,
                        options
                    )
                    .then((response) => {
                        if (response.id) {
                            resolve(response.id);
                        } else {
                            reject(response.message);
                        }
                    })
                    .catch((error) => {
                        console.error("ERROR", error);
                        reject(error.message);
                    })
            );
        },
        [currentCulture, b2bRequest, customerNumber]
    );

    const getCompletedOrder: CartHook["getCompletedOrder"] = useCallback(
        (cartId, options) => {
            const properties = {};
            properties["culture"] = "da";

            if (customerNumber) {
                properties["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .get(`/carts/getcompletedcart/${cartId}`, properties, options)
                    .then((data) => {
                        if (data.id) {
                            resolve(mapToOrder(data));
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
            );
        },
        [b2bRequest, customerNumber, mapToOrder]
    );

    const updateOrderlineDriverMessage: CartHook["updateOrderlineDriverMessage"] = useCallback(
        (properties, options) => {
            const props = {
                id: cartId,
                lineId: properties.lineId,
                driverMessage: properties.driverMessage,
            };

            return new Promise((resolve, reject) =>
                b2bRequest
                    .patch(`/carts/customers/${customerNumber}/savedrivermessage`, props, options)
                    .then((data) => {
                        if (data.id) {
                            updateCartData(mapToCartData(data));
                            resolve(true);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, cartId, customerNumber, mapToCartData, updateCartData]
    );

    // Get secret on page load
    useEffect(() => {
        if (!cartId && !orderSecret && !givenOrderSecret) {
            getStoredCartId();
        }
    }, [cartId, orderSecret, givenOrderSecret, getStoredCartId]);

    // Get cart on page load (using secret or customer number)
    useEffect(() => {
        if (givenOrderSecret) getCartById();
        else if (!cartId && (orderSecret || customerNumber)) {
            getCart().catch(() => {
                if (customerNumber) {
                    // If cart doesn't exist, reset the stored secret
                    resetCartId();
                }
            });
        }
    }, [cartId, orderSecret, customerNumber, getCart, resetCartId, givenOrderSecret, getCartById]);

    // Reset cart when customer number is changed
    useEffect(() => {
        if (customerNumber && previousCustomerNumber && customerNumber !== previousCustomerNumber) {
            resetCart();
        }
    }, [resetCart, customerNumber, previousCustomerNumber]);

    return (
        <CartContext.Provider
            value={{
                cartId,
                orderSecret,
                priceData,
                priceStatus,
                orderLines,
                customerDetails,
                shippingDetails,
                latestShippingDate,
                cropProtection,
                updateCartOrderLines,
                updateOrderlineDriverMessage,
                addToCart,
                updateCart,
                removeFromCart,
                resetCart,
                saveContactInfo,
                updateExpressDeliveryModes,
                expressDeliveryModes,
                saveDeliveryInfo,
                completeOrder,
                completeOrderSimple,
                completeOrderSimple2,
                getCompletedOrder,
                isLoadingCart,
                isUpdatingCart,
                isFarmInTimeCart: typeof givenOrderSecret !== "undefined",
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = (): CartHook => React.useContext(CartContext);
